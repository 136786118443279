:root {
  --ListingPage_smallButtonHeight: 54px;
  --ListingPage_buttonPadding: 15px;
  --ListingPage_favoriteButtonBorderWidth: 2px;

  scroll-behavior: smooth;
  scroll-padding-top: var(--topbarHeight);
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
}

@media only screen and (min-width: 1024px) {
  .pageRoot {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .threeToTwoWrapper {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .threeToTwoWrapper {
    padding: 36px 36px 0 36px;
  }
}

.sectionMediaVideo {
  background-color: black;
}

.imageChip {
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px 0 0 12px;
}

.imageChipV2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 12px 12px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  z-index: 2;
}

.imageChipAvatar {
  background: black;
}

.imageChipNoProfileImageIcon {
  display: flex;
  height: 24px;
  width: 24px;
}

.imageCarouselArrows {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  cursor: pointer;
  border: 0;
  padding: 5px;
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
}

.imageCarouselArrows:hover {
  background: rgba(0, 0, 0, 0.2);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 100%; /* 1:1 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .aspectWrapper {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: var(--gray10);
  background-color: var(--gray80);
  z-index: 2; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.actionBarDesktop {
  position: absolute;
  display: flex;
  justify-content: space-between;
  color: var(--gray10);
  background-color: var(--gray80);
  opacity: 0.8;
  z-index: 2;
  width: 100%;
}

@media only screen and (min-width: 1921px) {
  .actionBarDesktop {
    top: 24px;
  }
}

.ownListingText {
  margin: 14px 12px 11px 24px;
}

@media only screen and (min-width: 768px) {
  .ownListingText {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .closedListingText {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);
  transition: var(--transitionStyleButton);
}

.editListingLink:hover {
  color: var(--matterColorLight);
  -webkit-text-decoration: none;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .editListingLink {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: var(--transitionStyleButton);
}

@media only screen and (min-width: 768px) {
  .rootForImage {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }

  .rootForImage:hover {
    transform: scale(1.005);
    box-shadow: var(--boxShadowListingCard);
  }
}

.contentContainer {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .contentContainer {
    max-width: 1056px;
    padding: 10px 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .contentContainer {
    max-width: 1200px;
    padding: 10px 36px 36px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1921px) {
  .contentContainer {
    max-width: 1500px;
  }
}

.carouselContainer {
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .imagesContent {
    padding-right: 20px;
  }
}

/* Need a wrapper under details container because attributes set in detailsContent get overriden by the Grid item properties */
.detailsWrapper {
  padding: 24px 24px 0;

  /* Sometimes the description HTML can be really wide */
  max-width: 100vw;
  box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
  .detailsWrapper {
    /* Default Values to offset the values set above */
    padding: 0;
    max-width: none;
    box-sizing: content-box;
  }
}

@media only screen and (min-width: 768px) {
  .detailsContent {
    display: block;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .detailsContent {
    padding-left: 40px;
  }
}

.avatarMobile {
  display: flex;
}

@media only screen and (min-width: 768px) {
  .avatarMobile {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
}

@media only screen and (min-width: 768px) {
  .avatarDesktop {
    display: flex;
  }
}

.sectionHeading {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .sectionHeading {
    margin-top: 22px;
    display: flex;
  }
}

.desktopPriceContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

@media only screen and (min-width: 1024px) {
  .desktopPriceContainer {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0;
  }
}

.desktopOriginalPriceValue {
  color: var(--matterColor);
  margin: 0;
  margin-right: 8px;
  font-size: 24px;
}

@media only screen and (min-width: 768px) {
  .desktopOriginalPriceValue {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPriceValue {
  color: var(--priceColor);
  margin: 0;
  font-size: 24px;
}

@media only screen and (min-width: 768px) {
  .desktopPriceValue {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  color: var(--matterColor);
}

@media only screen and (min-width: 768px) {
  .desktopPerUnit {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.infoPanel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .infoPanel {
    width: inherit;
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .title {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.infoLine {
  margin: 8px 0;
}

.infoDescription {
  white-space: pre-wrap;
  padding-bottom: 32px;

  /* Need this because descriptionHTML imported from brands usually have p. We want it to be the same size as the other lines on the page */
}

.infoDescription p,
.infoDescription li,
.infoDescription ul,
.infoDescription strong {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

.infoDescription strong {
  font-weight: bolder;
}

.infoDescription img {
  max-width: 100%;
}

.infoDescription ul {
  list-style-type: disc;
  margin-left: 10px;
}

.quirk {
  margin: 4px;
}

.sizeGuideLink {
  display: flex;
  align-items: center;
}

.sizeGuideRulerIcon {
  margin-right: 4px;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) {
  .sectionDescription {
    padding: 0;
    margin-bottom: 52px;
  }
}

.descriptionTitle {
  /* Font */
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 13px;
}

@media only screen and (min-width: 768px) {
  .descriptionTitle {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;
}

@media only screen and (min-width: 768px) {
  .sectionFeatures {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 16px;
}

@media only screen and (min-width: 768px) {
  .featuresTitle {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
}

@media only screen and (min-width: 768px) {
  .description {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) {
  .sectionMap {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .locationTitle {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.reviewsHeading {
  color: var(--matterColorAnti);
  margin: 52px 0 20px 0;
}

@media only screen and (min-width: 768px) {
  .reviewsHeading {
    margin: 53px 0 27px 0;
  }
}

@media only screen and (min-width: 1024px) {
  .reviewsHeading {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) {
  .sectionHost {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .sectionHost {
    margin-bottom: 3px;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.longWord {
  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.buttonsWrapper {
  /* Fixed "Add to Bag" */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  box-shadow: var(--boxShadowTop);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: var(--zIndexPopup);
  align-items: stretch;
}

@media only screen and (min-width: 768px) {
  .buttonsWrapper {
    /* Remove the styling for for fixed "Add to Bag" */
    position: relative;
    width: unset;
    padding: unset;
    box-shadow: none;
    z-index: var(--zIndexTopbar) - 1;
  }
}

.addToBagButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: unset; /* Reset button default min-height */
  width: 100%;
  flex-grow: 1;
}

.favoriteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: var(--borderRadius);
  margin-left: 8px;
  width: var(--ListingPage_smallButtonHeight);
  height: var(--ListingPage_smallButtonHeight);
  padding: calc(var(--ListingPage_buttonPadding) - var(--ListingPage_favoriteButtonBorderWidth));
}

.favoriteButton:hover {
  border-color: var(--marketplaceColor);
}

.favoriteIcon {
  align-self: center;
  height: 24px;
  width: 24px;
}

@media only screen and (min-width: 768px) {
  .favoriteIcon {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .shopButton {
    display: inline-block;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
}

.comingSoonImg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

@media only screen and (min-width: 550px) {
  .comingSoonImg {
    border-top-left-radius: var(--borderRadius);
  }
}

@media only screen and (min-width: 768px) {
  .comingSoonImg {
    width: auto;
    border-top-left-radius: unset;
  }
}

.sectionIso {
  cursor: pointer;
}

.sectionIcon {
  height: 32px;
  width: 32px;
}

.thumbnailsSwiper {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .thumbnailsSwiperWithTabs {
    height: calc(100% - 32px); /* Offset tabs size and top margin */
  }
}

.carouselMediaSwiper {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.swiperCarouselImage {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
}

@media only screen and (min-width: 768px) {
  .swiperCarouselImage {
    max-height: 100%;
  }
}

.brandDirectExplainerIcon {
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  margin: 0 24px 0 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .brandDirectExplainerIcon {
    margin-right: 2px 24px 2px 2px;
  }
}
