.consolidatedTable {
  overflow-y: auto;
  width: 100%;
}

.consolidatedTableRow {
  border-bottom: 1px solid var(--gray10);
  cursor: pointer;
}

.consolidatedTableRow:hover,
.consolidatedTableRowSelected {
  background-color: var(--gray10);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.checkmarkChecked,
.checkmarkUnchecked {
  height: 22px;
  width: 22px;
  transition: transform 0.3s;
}

.consolidatedTableRow:hover .checkmarkUnchecked,
.consolidatedTableRow:hover .checkmarkChecked {
  transform: scale(1.2);
}

.consolidatedTableCell {
  padding: 8px;
  word-wrap: break-word;
}

@media only screen and (min-width: 1024px) {
  .consolidatedTableCell {
    padding: 8px 2px;
  }
}

.consolidatedTableRadioButtonCell {
  padding: 8px 0 10px 12px;
}

.consolidatedTableHeader {
  padding: 10px 8px;
}

@media only screen and (min-width: 1024px) {
  .consolidatedTableHeader {
    padding: 10px 0;
  }
}

.consolidatedTableConditionHeader {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
}

thead {
  border-bottom: 1px solid var(--gray20);
}

.infoIcon {
  height: 10px;
  width: 10px;
}

.caretIcon {
  height: 16px;
  width: 16px;
}

.consolidatedTableVariantCell {
  max-width: 100px;
}

.searchAllLink:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.linkRowIcon {
  height: 24px;
  width: 24px;
}

.sectionConsolidatedListingsLinkRowWrapper {
  padding: 8px;
  cursor: pointer;
}
